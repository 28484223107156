
i, em { font-style: italic; }
b, strong { font-weight: bold; }

@media (min-width: map-get($aui-breakpoint, 'huge')) {
    .aui-headline-1 {
        font-size: 119px;
        line-height: 132px;
    }
    .aui-headline-2 {
        font-size: 61px;
        line-height: 72px;
    }
    .aui-headline-3 {
        font-size: 31px;
        line-height: 36px;
    }
}

.aui-font-extended {
  font-family: $aui-font-extended;
}

.aui-color-text-gray50 {
  color: $aui-color-gray50;
}

.aui-textlink {
    cursor: pointer;
}

.audiicon {
    vertical-align: middle;
}

// .aui-textfield .audiicon {
//     top: auto;
//     bottom: 0;
// }
// 
// .aui-headline-1, .aui-headline-2, .aui-headline-3, .aui-headline-4, .aui-headline-5 {
// }
// 
// .aui-button.textlink {
//     text-decoration: underline;
//     padding: 0;
// }
// 
// .aui-button.aui-button--primary.red {
//     background-color: $aui-color-red;
//     border-color: $aui-color-red;
//     color: $aui-color-white;
// }
// 
// .aui-fieldset__fields {
//     flex-wrap: wrap;
//     margin: 0;
// }
// .aui-fieldset--radios.stacked {
//     .aui-fieldset__field {
//         width: 100%;
//     }
// }

// modal body needs to be wider so it lines up with the header
.aui-modal-dialog__body {
  width: 83.33333%;
  max-width: 1120px;
}
// because we've set the modal breakpoint to 0, need a bit more width for mobile
@media (max-width: map-get($aui-breakpoint, 'small') - 1) {
  .aui-modal-dialog__body {
    width: 92%;
    min-width: 290px;
  }
}
// modal backdrop needs to be lighter
.aui-modal-backdrop {
  background: rgba(0,0,0,0.7);
}
