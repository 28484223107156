$env: 'production';
@import "variables";

@import '../audi-ui-components/styles/main';

@import 'aui-overrides';

// for brand site header
@font-face {
    font-family: "AudiTypeNormal";
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot");
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.woff") format("woff"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.svg#AudiType-Normal") format("svg");
    font-weight: normal;
    font-style: normal;
}

@import 'bootstrap';


/************************************
* base layout
************************************/
html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

#App {
  // background: $aui-color-gray95;
}
.app-outer {
  max-width: 1920px;
  margin: 0 auto;
}
.app-inner {
  padding-left: var(--one-header-side-spacing);
  padding-right: var(--one-header-side-spacing);
}

.app-outdent {
  // for hero carousel
  @media (max-width: map-get($aui-breakpoint, medium) - 1) {
    margin-left: calc(var(--one-header-side-spacing) * -1);
    margin-right: calc(var(--one-header-side-spacing) * -1);
  }
}

.maincol {
}
.sidebar {
  flex: 0 0 20vw;
  @media (min-width: map-get($aui-breakpoint, huge)) {
    flex: 0 0 385px;
  }
}
.section {
  .section__main {
    flex: 1 1 auto;
  }
}
.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 100;
  border-top: 1px solid #000;
  @media (min-width: map-get($aui-breakpoint, medium)) {
    position: relative;
    bottom: auto;
  }
}

.spinner-wrapper {
  position: absolute;
  background: rgba(255,255,255,0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;
  >.aui-spinner, .spinner-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.sticky {
    >.aui-spinner, .spinner-inner {
      position: sticky;
      margin-top: 100px;
    }
  }
  &.placeholder {
    position: relative;
    min-height: 300px;
  }
}

.postcode-price {
  .aui-textfield__error {
    display: none;
  }
}

.dealer-by-postcode {
  max-width: 640px;
  .dealer {
    cursor: pointer;
    max-height: 150px;
    overflow: hidden;
    margin-bottom: 1rem;
    transition: all 0.3s $aui-easing;
    &:hover {
      background-color: $aui-color-gray90;
    }
  }
  .dealer.selected {
    background-color: $aui-color-black;
    cursor: default;
  }
  .dealer.not-selected {
    max-height: 0;
    margin-bottom: 0;
  }
}

hr {
  border-color: $aui-color-gray85;
  border-style: solid;
  border-width: 1px 0 0;
}

.accordion {
  .accordion-button {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
    &::after {
      margin-left: 1rem;
      margin-top: -3px;
      width: 1rem;
      height: 1rem;
      background-size: contain;
    }
  }
}

.price-table {
  width: calc(100% - 3rem);
  td {
    vertical-align: top;
    padding: .6rem 0;
  }
  td:last-child {
    text-align: right;
    padding-left: 10px;
  }
  tr.sep td {
    border-top: 1px solid;
    padding-top: 1rem;
  }
}

.optrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3%;
  .optbtn {
    flex: 0 0 auto;
    width: 47%;
    padding: 1.2em 0;
    margin-bottom: .5em;
    opacity: .3;
    &.selected {
      opacity: 1;
      &::after {
        content: "";
        background: $aui-color-green url('data:image/svg+xml,#{aui-svg-tick($aui-color-white)}') center center no-repeat;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transform: translate(20%,30%);
      }
    }
    .aui-button__text {
      text-align: center;
      @media (max-width: map-get($aui-breakpoint, small) - 1) {
        font-size: 90%;
      }
    }
    svg {
      margin: 0 0 6px;
      @media (min-width: map-get($aui-breakpoint, small)) {
        margin: 10px 0;
      }
      stroke-width: 0;
    }
    
  }
}
.model-section {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      flex: 0 1 auto;
    }
  }
  .optrow {
    flex: 1 1 auto;
    gap: 0;
    @media (min-width: map-get($aui-breakpoint, small)) {
      gap: 3%;
    }
    .optbtn {
      width: 50%;
      @media (min-width: map-get($aui-breakpoint, small)) {
        width: 47%;
      }
    }
  }
}

.equipment-desc {
  font-size: 90%;
  line-height: 1.6;
}

.equipment-list {
  padding: 0;
  list-style: none;
  font-size: 90%;
}

.paint-picker {
  .aui-fieldset__fields {
    margin: 0;
  }
  .aui-fieldset__field {
    flex: 0 1 auto;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .paint-swatch {
    position: relative;
    border: 1px solid #fff;
    width: 3em;
    height: 3em;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: .5em;
    margin-bottom: .5em;
    &.selected {
      border-color: #000;
    }
    .paint-swatch__select {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 24px;
      height: 24px;
      background: $aui-color-green;
      color: $aui-color-white;
      border-radius: 50%;
      transform: translate(30%,30%);
    }
  }
  .text-end .paint-swatch {
    margin-right: 0;
    margin-left: .5em;
  }
}

.interior {
  .interior__hero {
    padding-top: 31%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ccc;
  }
  .introw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 0;
  }
  .intbtn {
    border: none;
    box-shadow: none;
    padding: 20% 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ccc;
    flex: 1 1 auto;
    opacity: .3;
    cursor: pointer;
    transition: opacity .5s;
    &:hover {
      opacity: .6;
    }
    &.selected {
      opacity: 1;
    }
  }
  th {
    text-align: right;
    padding: .2rem 1rem .2rem 0;
  }
  td {
    padding: .2rem 0;
  }
}

@import "embla";
